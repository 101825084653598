import { createAction } from 'src/lib/createAction';
import { createAction as toolkitCreateAction } from '@reduxjs/toolkit';
import { OptionsObject, SnackbarMessage } from 'notistack';

export const snackbarActions = {
  enqueueSnackbar: toolkitCreateAction(
    'notification/snackbar/enqueue',
    ({
      key,
      message,
      options,
    }: {
      key?: string;
      message: SnackbarMessage;
      options?: OptionsObject;
    }) => ({
      payload: {
        key: key ?? `${new Date().getTime() + Math.random()}`,
        message,
        options,
      },
    })
  ),
  closeSnackbar: createAction('notification/snackbar/close')<{
    key: string;
  }>(),
  closeAllSnackbars: createAction('notification/snackbar/closeAll')(),
  removeSnackbar: createAction('notification/snackbar/remove')<{
    key: string;
  }>(),
};
