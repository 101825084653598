import Axios, { AxiosError, AxiosResponse } from 'axios';

export const flatten = <T>(response: AxiosResponse<T>) => response.data;

export const flattenError = <T, R>(
  error: AxiosError<T>,
  mapFn?: (error: AxiosError<T>) => R
) => {
  if (Axios.isCancel(error)) {
    return error;
  } else {
    return mapFn?.(error) ?? error;
  }
};
